<template>
  <div class="fill-height primary1 white--text">
    <v-container class="fill-height" fluid>
      <v-row
        v-if="initLoading"
        justify="center"
        style="z-index: 10"
        align="center"
      >
        <loading-component />
      </v-row>
      <v-row v-show="!initLoading" style="padding: 80px 10px">
        <v-col
          cols="12"
          md="6"
          class="d-flex"
          :class="isTablet ? 'justify-center' : ['justify-end', 'pr-5']"
        >
          <div style="width: 100%; max-width: 500px">
            <div :class="!isMobile && ['primary', 'pa-7']">
              <div>
                <div :class="!isMobile && 'd-flex'">
                  <div class="flex-grow-1">
                    <div
                      class="font-weight-bold"
                      :class="isTablet ? 'text-h5' : 'text-h5'"
                    >
                      WithU Subscription
                    </div>
                    <div
                      v-if="freeTrial"
                      class="font-weight-medium"
                      :class="isTablet ? 'text-h6' : 'text-h6'"
                    >
                      {{ freeTrial }} free trial
                    </div>
                    <div
                      class="font-weight-medium"
                      :class="isMobile && 'flex-grow-1'"
                      v-if="discountPercent"
                    >
                      {{ `${discountPercent}% Discount` }}
                    </div>
                  </div>
                  <div
                    style="color: #bcbcbc; font-size: 19px"
                    :class="[
                      isMobile && 'd-flex',
                      !isMobile && 'text-end',
                      isMobile && 'mt-3',
                    ]"
                  >
                    <div
                      class="font-weight-medium"
                      :class="isMobile && 'flex-grow-1'"
                      style="text-decoration: line-through"
                      v-if="discountPercent"
                    >
                      {{ defaultAmount }}
                    </div>
                    <div
                      class="font-weight-bold"
                      style="color: var(--v-secondary-base)"
                    >
                      {{ amount }}
                    </div>
                  </div>
                </div>
                <div class="divider my-3"></div>
                <div
                  class="lightGrey--text font-weight-medium"
                  style="font-size: 15px"
                >
                  <div style="height: 25px" v-if="freeTrial">
                    After trial ends
                    <span
                      class="font-weight-bold text-subtitle-1 white--text"
                      >{{ amount }}</span
                    >
                  </div>
                  <div style="height: 25px" v-if="freeTrial">
                    Get a reminder 7 days before your trial ends.
                  </div>
                  <div style="height: 25px">Cancel anytime, no commitment.</div>
                </div>
              </div>

              <div class="d-flex mt-5">
                <v-btn
                  color="secondary"
                  outlined
                  text
                  dark
                  class="flex-grow-1 black--text font-weight-bold text-subtitle-2 text-none mr-2"
                  @click="upgrade"
                  style="height: 55px"
                  :style="
                    annual
                      ? `color: #bcbcbc !important;
                    border: 1px solid #bcbcbc; opacity: 0.3`
                      : `color: white !important;
                    border: 1px solid var(--v-secondary-base);`
                  "
                >
                  Keep monthly
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  text
                  dark
                  class="flex-grow-1 flex-column d-flex black--text font-weight-bold text-subtitle-2 text-none ml-2"
                  @click="upgrade"
                  style="height: 55px"
                  :style="
                    !annual
                      ? `color: #bcbcbc !important;
                    border: 1px solid #bcbcbc; opacity: 0.3`
                      : `color: white !important;
                    border: 1px solid var(--v-secondary-base);`
                  "
                >
                  Upgrade to annual
                  <span
                    class="text-caption ml-1"
                    :style="annual && 'color: var(--v-secondary-base)'"
                    >save {{ yearlyDiscountPercent }}%</span
                  >
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex align-center"
          :class="isTablet ? 'justify-center' : ['justify-start', 'pl-5']"
        >
          <div style="width: 100%; max-width: 500px">
            <div>
              <div
                class="d-flex align-start justify-space-between mt-5"
                style="height: 20px"
              >
                <div
                  class="font-weight-bold"
                  :class="isTablet ? 'text-subtitle-1' : 'text-subtitle-1'"
                  style="letter-spacing: 1px !important"
                >
                  Credit card
                </div>
                <div>
                  <font-awesome-icon
                    :icon="icons.visa"
                    size="lg"
                    class="ml-1"
                    :style="{ color: 'white' }"
                  />
                  <font-awesome-icon
                    :icon="icons.mastercard"
                    size="lg"
                    class="ml-1"
                    :style="{ color: 'white' }"
                  />
                  <font-awesome-icon
                    :icon="icons.amex"
                    size="lg"
                    class="ml-1"
                    :style="{ color: 'white' }"
                  />
                  <font-awesome-icon
                    :icon="icons.jcb"
                    size="lg"
                    class="ml-1"
                    :style="{ color: 'white' }"
                  />
                  <font-awesome-icon
                    :icon="icons.dinersClub"
                    size="lg"
                    class="ml-1"
                    :style="{ color: 'white' }"
                  />
                </div>
              </div>

              <!-- <input
                v-model="name"
                class="cardElement"
                placeholder="Cardholders Name*"
              /> -->

              <div id="card-element" class="cardElement"></div>

              <v-alert
                class="font-weight-medium text-caption"
                color="red"
                border="left"
                text
                dense
                v-show="cardError"
                >{{ cardError }}</v-alert
              >
              <v-btn
                color="secondary"
                block
                dark
                class="black--text font-weight-bold text-subtitle-1 my-4 px-10"
                style="height: 55px"
                :disabled="!canSubmit || loading"
                @click="createPayment"
                :loading="loading"
              >
                Start Your Membership
              </v-btn>

              <div id="payment-request-button" class="my-2">
                <!-- A Stripe Element will be inserted here. -->
              </div>
            </div>

            <div class="lightGrey--text mt-5" style="font-size: 10px">
              <div v-if="freeTrial">
                We require your payment information to initiate the free trial,
                but you will not be charged until after the {{ freeTrial }} free
                trial period. We will remind you prior to charging your card and
                your WithU Membership will renew automatically after the trial
                period at {{ amount }} until you cancel.
              </div>
              <div v-else>
                We require your payment information to initiate your account. We
                will remind you prior to charging your card and your WithU
                Membership will renew automatically at {{ amount }} until you
                cancel.
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { mapState, mapActions, mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCcAmex } from "@fortawesome/free-brands-svg-icons";
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCcDinersClub } from "@fortawesome/free-brands-svg-icons";
import { faCcJcb } from "@fortawesome/free-brands-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
    StripeElementCard,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      paymentRequest: null,
      card: null,
      complete: false,
      initLoading: true,
      currency: "USD",
      loading: false,
      annual: false,
      cardError: null,
      error: null,
      icons: {
        visa: faCcVisa,
        mastercard: faCcMastercard,
        amex: faCcAmex,
        jcb: faCcJcb,
        dinersClub: faCcDinersClub,
      },
      products: [
        {
          product_id: "prod_JJWLQpeaiC5qbW",
          term: "year",
          prices: [
            {
              price_id: "price_1IgtJ1LdUrrOSabidKBy5FOC",
              currency: "GBP",
              symbol: "£",
              price: 79.99,
              subunit: 7999,
            },
            {
              price_id: "price_1IgtJ1LdUrrOSabiOsDCYCuz",
              currency: "USD",
              symbol: "$",
              price: 79.99,
              subunit: 7999,
            },
            {
              price_id: "price_1IgtJ1LdUrrOSabiG80pxkve",
              currency: "EUR",
              symbol: "€",
              price: 79.99,
              subunit: 7999,
            },
          ],
        },
        {
          product_id: "prod_JJWLQpeaiC5qbW",
          term: "month",
          prices: [
            {
              price_id: "price_1IgtJ1LdUrrOSabi78fJZp2W",
              currency: "GBP",
              symbol: "£",
              price: 9.99,
              subunit: 999,
            },
            {
              price_id: "price_1IgtJ1LdUrrOSabiJlC8jx68",
              currency: "USD",
              symbol: "$",
              price: 9.99,
              subunit: 999,
            },
            {
              price_id: "price_1IgtJ1LdUrrOSabibwyNL7Pp",
              currency: "EUR",
              symbol: "€",
              price: 9.99,
              subunit: 999,
            },
          ],
        },
      ],
    };
  },
  watch: {
    annual(val) {
      this.paymentRequest.update({
        total: {
          label: val ? "Yearly Subscription" : "Monthly Subscription",
          amount: this.price.subunit,
        },
      });
      this.event({
        event: "Toggle Plan",
        properties: {
          plan_billing: val ? "Annually" : "Monthly",
        },
      });
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      freeTrial: "account/freeTrial",
    }),
    stripeElements() {
      return this.$stripe.elements();
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isTablet() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    canSubmit() {
      return this.complete;
    },
    stripe() {
      return this.account.stripe;
    },
    deal() {
      return this.account.redeem.deal;
    },
    amount() {
      const price = this.price.price;
      var discount = price * (this.discountPercent / 100);
      var newPrice = price - discount;
      return `${this.price.symbol}${newPrice.toFixed(2)}/${this.product.term}`;
    },
    defaultAmount() {
      const price = this.price.price;
      return `${this.price.symbol}${price.toFixed(2)}/${this.product.term}`;
    },
    discountPercent() {
      if (this.deal) {
        var subscription = this.deal.subscription;
        if (subscription && subscription.discount_percent) {
          return subscription.discount_percent;
        }
      }
      return 0;
    },

    yearlyDiscountPercent() {
      var totalYearly = this.yearlyPrice.price;
      var totalMonth = this.monthlyPrice.price * 12;

      return (((totalMonth - totalYearly) * 100) / totalMonth).toFixed(0);
    },

    product() {
      return this.annual ? this.products[0] : this.products[1];
    },
    price() {
      return this.product.prices.find((p) => p.currency == this.currency);
    },
    monthlyPrice() {
      return this.products[1].prices.find((p) => p.currency == this.currency);
    },
    yearlyPrice() {
      return this.products[0].prices.find((p) => p.currency == this.currency);
    },
  },

  methods: {
    ...mapActions({
      subscribe: "account/subscribe",
      event: "account/events",
    }),

    createPayment() {
      // // console.log((this.stripe);
      // this.handlePaymentThatRequiresCustomerAction(this.stripe);
      // return;

      this.cardError = null;
      this.loading = true;

      var paymentMethod = {
        type: "card",
        card: this.card,
        // billing_details: {
        //   name: this.name,
        // },
      };

      this.$stripe.createPaymentMethod(paymentMethod).then((result) => {
        // console.log((result);
        if (result.error) {
          this.displayError(result);
        } else {
          this.createSubscription({ paymentMethodId: result.paymentMethod.id });
        }
      });
    },

    displayError(event) {
      if (event.error) {
        this.cardError = event.error.message;
      } else {
        this.cardError = null;
      }
      if (event.complete != null) {
        this.complete = event.complete;
      }
      this.loading = false;
    },
    createSubscription({ paymentMethodId, ev }) {
      var payload = {
        paymentMethodId,
        priceId: this.price.price_id,
        productId: this.product.product_id,
        currency: this.currency,
      };

      this.subscribe(payload)
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: payload.paymentMethodId,
            priceId: payload.priceId,
            ...result,
            ev,
          };
        })
        .then(this.handlePaymentThatRequiresCustomerAction)
        .then(this.handleRequiresPaymentMethod)
        .then(this.onSubscriptionComplete)

        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          // console.log((error, "error");
          this.displayError(error);
        });
    },

    handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry,
      ev,
    }) {
      if (
        subscription &&
        (subscription.status === "active" || subscription.status === "trialing")
      ) {
        // Subscription is active, no customer actions required.
        if (ev) {
          ev.complete("success");
        }
        return { subscription, priceId, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      let paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent;

      // console.log((paymentIntent, "payment intent");

      if (
        paymentIntent.status === "requires_action" ||
        (isRetry === true && paymentIntent.status === "requires_payment_method")
      ) {
        return this.$stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            // console.log((result);
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              if (ev) {
                ev.complete("fail");
              }
              throw result;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                // Show a success message to your customer.
                if (ev) {
                  ev.complete("success");
                }
                return {
                  priceId: priceId,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                  paymentSuccess: true,
                };
              }
            }
          });
      } else {
        // No customer action needed.
        return { subscription, priceId, paymentMethodId };
      }
    },

    handleRequiresPaymentMethod({
      subscription,
      paymentMethodId,
      priceId,
      paymentSuccess,
    }) {
      if (subscription) {
        if (
          subscription.status === "active" ||
          subscription.status === "trialing"
        ) {
          // subscription is active, no customer actions required.
          return { subscription, priceId, paymentMethodId, paymentSuccess };
        } else if (
          subscription.latest_invoice.payment_intent &&
          subscription.latest_invoice.payment_intent.status ===
            "requires_payment_method"
        ) {
          throw { error: { message: "Your card was declined." } };
        }
      }
      return { subscription, priceId, paymentMethodId, paymentSuccess };
    },

    onSubscriptionComplete(result) {
      // Payment was successful.
      var success = false;
      if (
        result.subscription &&
        (result.subscription.status === "active" ||
          result.subscription.status === "trialing")
      ) {
        success = true;
      }
      if (result.paymentSuccess) {
        success = true;
      }
      if (success) {
        this.$router.push("/download");
      }

      this.loading = false;
    },

    upgrade() {
      this.annual = !this.annual;
    },

    async init() {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const location = await response.json();

        // // console.log((location);

        var country;
        if (location && location.currency) {
          const c = location.currency;
          if (c == "USD" || c == "GBP" || c == "EUR") {
            this.currency = c;
          }
          country = location.country;
        }

        this.setupStripe(country);
        this.initLoading = false;
      } catch (e) {
        // console.log((e);
        this.initLoading = false;
      }
    },
    setupStripe(country) {
      const style = {
        base: {
          color: "white",
          fontWeight: 500,
          fontFamily: "Helvetica Neue",
          fontSize: "15px",
          lineHeight: "40px",
          "::placeholder": {
            color: "#706882",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      this.card = this.stripeElements.create("card", {
        style: style,
      });
      this.card.mount("#card-element");

      let that = this;
      this.card.on("change", function (event) {
        that.displayError(event);
      });

      if (country) {
        this.paymentRequest = this.$stripe.paymentRequest({
          country: country,
          currency: this.currency.toLowerCase(),
          total: {
            label: "Montly Subscription",
            amount: this.price.subunit,
          },
          // requestPayerName: true,
          // requestPayerEmail: true,
        });

        var prButton = this.stripeElements.create("paymentRequestButton", {
          paymentRequest: this.paymentRequest,
        });

        // Check the availability of the Payment Request API first.
        this.paymentRequest.canMakePayment().then(function (result) {
          if (result) {
            prButton.mount("#payment-request-button");
          } else {
            document.getElementById("payment-request-button").style.display =
              "none";
          }
        });

        let _this = this;
        this.paymentRequest.on("paymentmethod", function (ev) {
          _this.cardError = null;
          _this.loading = true;

          _this.createSubscription({
            paymentMethodId: ev.paymentMethod.id,
            ev,
          });
        });
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeMount() {
    this.initLoading = true;
  },

  beforeDestroy() {
    this.card.destroy();
    // this.cardNumber.destroy();
    // this.cardExpiry.destroy();
    // this.cardCvc.destroy();
  },
};
</script>

<style>
#card-error {
  color: red;
  padding-top: 10px;
}
</style>
